// const CryptoJS = require('crypto-js');
const crypto = require("crypto");
const KEY = "EncryptKey123456";
// const iv = "\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00";

export const encryptor = {
    aesEncrypt,
    aesDecrypt,
}

function aesEncrypt(data) {
    try {
        const iv = Buffer.alloc(16, 0);
        const cipher = crypto.createCipheriv("aes-128-cbc", KEY, iv);
        return cipher.update(data, "utf8", "base64") + cipher.final("base64");
      } catch (error) {
        //console.log("ENCRYPT ERROR - " + error);
      }
      return null;
}

function aesDecrypt(data) {
    try {
        data = data.replace(" ", "+");
        const iv = Buffer.alloc(16, 0);
        var cipher = crypto.createDecipheriv("aes-128-cbc", KEY, iv);
        var str = decodeURIComponent(
          cipher.update(data, "base64", "utf8") + cipher.final("utf8")
        );
        return str.replaceAll("+", " ");
      } catch (error) {
        //console.log("DECRYPT ERROR - " + error);
      }
      return null;
}