import Vue from 'vue'
import Router from 'vue-router'
import { encryptor } from '@/helpers/encryptor.js'

const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
	return routerPush.call(this, location).catch(error => error)
}

Vue.use(Router)

const MainLayout = () => import('@/layouts/mainLayout.vue')

const AuthenticationLayout = () => import('@/layouts/authenticationLayout.vue')
const Login = () => import('@/pages/auth/login/login.vue')
const Confirmation = () => import('@/pages/auth/confirmation/confirmation.vue')
const Register = () => import('@/pages/auth/register/register.vue')
const ResetPassword = () => import('@/pages/auth/resetPassword/resetPassword.vue')

const PageLayout = () => import('@/layouts/pageLayout.vue')
const Landing = () => import('@/pages/landing/landing.vue')
const Merchant = () => import('@/pages/merchant/merchant.vue')
const Faq = () => import('@/pages/faq/faq.vue')
const LoanApplicationLayout = () => import('@/layouts/loanApplicationLayout.vue')
// const LoanApplication = () => import('@/pages/loanApplication/loanApplication.vue')
const LoanApplicationList = () => import('@/pages/loanApplication/loanApplicationList.vue')
const LoanApplicationNew = () => import('@/pages/loanApplication/loanApplicationNew.vue')
const LoanApplicationEdit = () => import('@/pages/loanApplication/loanApplicationEdit.vue')

const User = () => import('@/pages/user/user.vue')
const Profile = () => import('@/pages/user/profile/profile.vue')
const UserResetPassword = () => import('@/pages/user/resetPassword/resetPassword.vue')
const UserLoyalty = () => import('@/pages/user/loyalty/loyalty.vue')

const NotFound = () => import('@/pages/notFound/notFound.vue')


// const Signature = () => import('@/pages/signature/signature.vue')
// const Confirmation = () => import('@/pages/login/confirmation.vue')
// const Complete = () => import('@/pages/complete/complete.vue')
// const Preview = () => import('@/pages/preview/preview.vue')

const router = new Router({
	mode: 'hash',
	routes: [
		{
			path: '/',
			component: MainLayout,
			name: 'Index',
			redirect: { name: 'Landing' },
			children: [
				{
					path: '/auth',
					name: 'Authentication',
					component: AuthenticationLayout,
					redirect: { name: 'Landing' },
					children: [
						{
							path: '/login',
							name: 'Login',
							component: Login,
							meta: { requiresAuth: false }
						},
						{
							path: '/confirmation',
							name: 'Confirmation',
							component: Confirmation,
							meta: { requiresAuth: false }
						},
						{
							path: '/register',
							name: 'Register',
							component: Register,
							meta: { requiresAuth: false }
						},
						{
							path: '/reset-password',
							name: 'ResetPassword',
							component: ResetPassword,
							meta: { requiresAuth: false }
						},
					]
				},
				{
					path: '/page',
					component: PageLayout,
					name: 'Page',
					redirect: { name: 'Landing' },
					children: [
						{
							path: '/home',
							name: 'Landing',
							component: Landing,
							meta: { requiresAuth: true }
						},
						{
							path: '/merchant',
							name: 'Merchant',
							component: Merchant,
							meta: { requiresAuth: true }
						},
						{
							path: '/faq',
							name: 'Faq',
							component: Faq,
							meta: { requiresAuth: true }
						},
						{
							path: '/loan-request/:loanApplicationTypeCode',
							name: 'LoanApplication',
							component: LoanApplicationLayout,
							redirect: { name: 'LoanApplicationList' },
							children: [
								{
									path: '/loan-request/:loanApplicationTypeCode/',
									name: 'LoanApplicationList',
									component: LoanApplicationList,
									meta: { requiresAuth: true }
								},
								{
									path: '/loan-request/:loanApplicationTypeCode/new',
									name: 'LoanApplicationNew',
									component: LoanApplicationNew,
									meta: { requiresAuth: true }
								},
								{
									path: '/loan-request/:loanApplicationTypeCode/:id/edit/:step',
									name: 'LoanApplicationEdit',
									component: LoanApplicationEdit,
									meta: { requiresAuth: true }
								},
							]
						},
						// {
						// 	path: '/complete',
						// 	name: 'Complete',
						// 	component: Complete
						// },
						{
							path: '/user',
							name: 'User',
							component: User,
							redirect: { name: 'Profile' },
							children: [
								{
									path: '/user/profile',
									name: 'Profile',
									component: Profile,
									// meta: { requiresAuth: true, requiresRole: ['admin'] }
									meta: { requiresAuth: true }
								},
								{
									path: '/user/loyalty',
									name: 'UserLoyalty',
									component: UserLoyalty,
									meta: { requiresAuth: true }
								},
								{
									path: '/user/reset-password',
									name: 'UserResetPassword',
									component: UserResetPassword,
									meta: { requiresAuth: true }
								}
							]
						}
					]
				},
			]
		},
		{ path: '/404', component: NotFound },  
		{ path: '*', redirect: '/404' },  
	]
})

router.beforeResolve(async (to, from, next) => {
	if (to.meta.requiresAuth) {
		const user = JSON.parse(encryptor.aesDecrypt(localStorage.getItem('user')))
		// const role = await getGroups()
		if (user) {
			//   if (to.meta.requiresRole) {
			// 	if (typeof role === 'object' && role.some(r => to.meta.requiresRole.includes(r))) {
			// 	  return next()
			// 	} else {
			// 	  Vue.prototype.$notify.error({
			// 		title: 'Алдаа',
			// 		message: 'Эрх тохирохгүй байна.'
			// 	  })
			// 	  signOut()
			// 	}
			//   } else {
			return next()
			//   }
		} else {
			console.log("to", to)
			// if ()
			// http://localhost:8080/#/loan-request/ecommerce/new?lrid=1
			return next({ path:'/login', query: { redirect: to.fullPath } })
		}
	} else {
		return next()
	}
})

export default router
