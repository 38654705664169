import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store'

import ElementUI from 'element-ui';
import '@/assets/css/theme/index.css';
import mn from 'element-ui/lib/locale/lang/mn'
import locale from 'element-ui/lib/locale'
locale.use(mn)
Vue.use(ElementUI);
import '@/assets/scss/main.scss'

import moment from 'moment'

Vue.prototype.moment = moment

Vue.config.productionTip = false

Vue.filter('formatCurrency', function (value) {
  return new Intl.NumberFormat().format(value) + '₮'
})

new Vue({
  router,
	store,
  data: {
    familyStateList: [],
    educationList: [],
    workSectorList: [],
    workRouteList: [],
    positionList: [],
    experienceList: [],
    incomeEvidenceList: [],
    incomeList: [],
    loanApplicationTypeList: [],
    selectedLoanApplicationType: null,
    loanApplication: {
      // system
      id: null,
      type_id: null,
      status_id: null,

      // huviin medeelel
      type: "",
      lastname: "",
      firstname: "",
      register_number: "",
      phone_number: "",
      email: "",
      customer_id: 0,

      // checkbox or switch
      family_state_id: null,
      education_id: null,
      work_route_id: null,
      work_sector_id: null,
      position_id: null,
      experience_id: null,
      address: "",

      // relation bolon zeel
      is_ipotek: false,
      has_relation: false,
      is_before_take_loan: false,
      lrid: null,
      loanApplicationRelation: null,
      loanApplicationBusiness: {
        application_id: null,
        address: null,
        average_expense: null,
        route_id: null,
        route_name: null,
        income_id: null,
        income: null,
      },
      loanApplicationInfo: null,
      loanApplicationAttachment: null,
      is_active: true,
      is_cib_accept: false,
      is_cib_check: false,
      is_sent: false,
      sent_at: null,
      step: 0,
    },
    newloanApplication: {
      // system
      id: null,
      type_id: null,
      status_id: null,

      // huviin medeelel
      type: "",
      lastname: "",
      firstname: "",
      register_number: "",
      phone_number: "",
      email: "",
      customer_id: 0,

      // checkbox or switch
      family_state_id: null,
      education_id: null,
      work_route_id: null,
      work_sector_id: null,
      position_id: null,
      experience_id: null,
      address: "",

      // relation bolon zeel
      is_ipotek: false,
      has_relation: false,
      is_before_take_loan: false,
      lrid: null,
      loanApplicationRelation: null,
      loanApplicationBusiness: {
        application_id: null,
        address: null,
        average_expense: null,
        route_id: null,
        route_name: null,
        income_id: null,
        income: null,
      },
      loanApplicationInfo: null,
      loanApplicationAttachment: null,
      is_active: true,
      is_cib_accept: false,
      is_cib_check: false,
      is_sent: false,
      sent_at: null,
      step: 0,
    }
  },
  render: h => h(App),
}).$mount('#app')
